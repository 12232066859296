import classNames from 'classnames';
import type { StaticImageData } from 'next/image';
import Image from 'next/image';
import type { FC, PropsWithChildren } from 'react';

type Props = {
  staticImage?: StaticImageData;
  absoluteImageClassName?: string;
  bgColor?: string;
};
const SpanWithAbsoluteImage: FC<PropsWithChildren<Props>> = ({
  absoluteImageClassName,
  bgColor,
  staticImage,
  children,
}) => {
  return (
    <span
      style={{ backgroundColor: bgColor ?? 'transparent' }}
      className="relative overflow-visible rounded-lg p-1"
    >
      {children}
      {staticImage && (
        <Image
          src={staticImage}
          alt=""
          className={classNames(
            absoluteImageClassName,
            'absolute max-w-none hidden md:flex'
          )}
        />
      )}
    </span>
  );
};

export default SpanWithAbsoluteImage;
