import classNames from 'classnames';
import { motion, useScroll, useSpring, useTransform } from 'framer-motion';
import type { FC, MutableRefObject, PropsWithChildren } from 'react';
import Marquee from 'react-fast-marquee';

type Props = {
  containerRef: MutableRefObject<null>;
  className?: string;
};
const ScrollAnimatedText: FC<PropsWithChildren<Props>> = ({
  children,
  containerRef,
  className,
}) => {
  const { scrollYProgress } = useScroll({ target: containerRef });
  const scrollYSpringBody = useSpring(scrollYProgress, {
    stiffness: 50,
    damping: 20,
    restDelta: 0.2,
  });
  const scrollTextTransformX = useTransform(
    scrollYSpringBody,
    [0, 1],
    ['-200vh', '0vh']
  );
  return (
    <Marquee
      autoFill
      play={false}
      className={classNames('overflow-hidden', className)}
      pauseOnHover
    >
      <motion.div
        style={{ x: scrollTextTransformX }}
        className="mx-8 flex flex-row flex-nowrap"
      >
        {children}
      </motion.div>
    </Marquee>
  );
};

export default ScrollAnimatedText;
